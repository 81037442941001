import { createRouter, createWebHistory } from 'vue-router'
import PaymentService from '../views/PaymentService.vue'
import CallBack from '../views/CallBack.vue'
import ErrorView from '../views/ErrorView.vue'
import InformationPage from '../views/InformationPage.vue'
import AgreementPage from "@/views/AgreementPage.vue";

const routes = [
  {
    path: '/callback/:id',
    name: 'callback',
    component: CallBack
  },
  {
    path: '/agreement/initref/:id',
    name: 'agreementPage',
    component: AgreementPage
  },
  {
    path: '/:name?/init/:id',
    name: 'payment',
    component: PaymentService
  },
  {
    path: '/:name?/initref/:id',
    name: 'paymentInitRef',
    component: PaymentService
  },
  {
    path: '/error/:id',
    name: 'error',
    component: ErrorView
  },
  {
    path: '/about',
    name: 'about',
    component: InformationPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
