<template>
  <div v-if="loading" class="spinner spinner-full">
    <pn-spinner></pn-spinner>
  </div>
  <router-view :token="token" v-if="!loading && !maintenance" />
  <ErrorPage v-if="maintenance" errorcode="1001" />
</template>
<script>
import axios from 'axios';
import ErrorPage from './components/ErrorPage.vue';
import { BASE_URLS } from './services/urlService';

export default {
  data() {
    return {
      loading: true,
      token: null,
      maintenance: false
    }
  },
  components: {
    ErrorPage,
  },
  mounted() {
    const config = window.paymentConfig || {};

    if (config.maintenance) {
      this.maintenance = true;
      this.loading = false;
      return;
    }

    /** If you explicity set loginEnabled to false we will skip login
      * Should only be set in local development
      */
    if (config.loginEnabled === false) {
      this.loading = false;
      return;
    }

    axios.get(BASE_URLS.LOGIN, {
      headers: config.loginHeaders ? config.loginHeaders() : undefined
    })
    .then(resp => {
      this.token = resp.data.accessToken;
      document.cookie = `access_token=${this.token}; path=/`;
    })
    .finally(() => {
      this.loading = false;
    });

  }
};
</script>


<style>

#app {
  /* font-family: "PostNordSans-Regular"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2d2013;;
}
</style>
