<template>
  <AgreementLoader
      v-if="loading"
      @agreementData="onGetAgreement"
      @error="onError"
  >
  </AgreementLoader>
</template>
<script>
import {BASE_URLS} from "@/services/urlService";
import axios from "axios";
import AgreementLoader from "../components/AgreementLoader";

export default {
  name: "AgreementPage",
  components: {
    AgreementLoader
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      agreementEvent: null,
    };
  },
  methods: {
    onGetAgreement(event) {

      this.loading = false;
      const agreement = event.data;
      console.log("Got event " + agreement.provider)
      if (agreement.provider === 'VIPPS') {
        this.processVippsAgreement(agreement.referenceId);
      }
    },

    processVippsAgreement(referenceId) {
      const url = `${BASE_URLS.PROCESSOR}/v4/agreement/vipps/init/reference/${referenceId}`;
      axios.post(url, {
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then((response) => {
        const redirectUrl = response.data.redirectUrl;
        window.location.href = redirectUrl;
      });
    },

    onError(error) {
      this.errorCode = error.response.status;
      this.loading = false;
    },
  },
};
</script>