<template>

  <div class="spinner spinner-full main">
    <pn-spinner></pn-spinner>
  </div>

</template>
<script>

import axios from "axios";
import {BASE_URLS} from '@/services/urlService';


export default {
  props: ['token'],
  emits: ['agreementData', 'error'],
  name: 'AgreementLoader',
  mounted() {
    let promise = null;
    promise = axios.get(`${BASE_URLS.PROCESSOR}/v4/agreement/reference/${this.$route.params.id}`, this.getRequestConfig());

    promise.then((response) => {

      const data = response.data;
      return {data};
    }).then(({data, error}) => {
      this.$emit('agreementData', {
        done: true,
        data,
        error
      });
    }).catch(err => {
      this.$emit('error', err);
    });
  },

  methods: {
    getRequestConfig() {
      if (!this.token) {
        return;
      }
      return {
        headers: {'Authorization': 'Bearer ' + this.token}
      };
    }
  }
}
</script>