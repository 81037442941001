<template>
  <div class="language-selector" :data-value="value" :data-list="list">
    <pn-button class="small"
               icon="globe"
               variant="outlined"
               appearance="light"
               small="true"
               @click="toggle()">
       <span>{{ value }}</span>
    </pn-button>

    <div v-if="visible">
      <ul class="ul-class">
        <li class="li-class"
            :class="{ current: item.name === value }"
            v-for="item in list"
            :key="item.id"
            @click="select(item)">
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {BASE_URLS} from '../services/urlService';

export default {
  name: 'language-selector',
  props: ['language', 'id', 'token'],
  emits: ['closeInformation'],
  data() {
    return {
      originalList: [
        {"id": "DK", "name": "Dansk"},
        {"id": "DE", "name": "Deutsch"},
        {"id": "EN", "name": "English"},
        {"id": "NO", "name": "Norsk"},
        {"id": "FI", "name": "Suomi"},
        {"id": "SE", "name": "Svenska"}
      ],
      list: [],
      value: "",
      visible: false,
      error: null
    }

  },
  mounted() {
    this.value = this.fetchLang(this.language)
    this.updateList();
  },
  methods: {
    fetchLang(lang) {
      let search = this.originalList.find((o) => o.id === lang);
      return search !== undefined ? search.name : "English";
    },
    getRequestConfig() {
      if (!this.token) {
        return undefined;
      }
      return {
        headers: {'Authorization': 'Bearer ' + this.token}
      };
    },
    toggle() {
      this.visible = !this.visible;
    },
    updateList() {
      this.list = this.originalList.filter(item => item.name !== this.value);
    },
    select(option) {
      this.value = option.name;
      this.list = this.originalList.filter(item => item.id !== option.id);
      this.$root.$i18n.locale = option.id;
      const data = {language: option.id};
      axios.put(`${BASE_URLS.PROCESSOR}/v4/payment/${this.id}/language`, data, this.getRequestConfig())
          .catch(err => {
            this.error = err.response?.data?.compositeFault?.faults[0]?.faultCode || 'Unknown error';
          });
    }
  }
};

</script>
<style scoped>
.language-selector {
  margin-top: 1em;
  margin-right: 0.5em;
  width: 7em;
  cursor: pointer;
  color: #005d92;
  position: relative;
  z-index: 1;
  gap: 0.25em;
}

.small {
  display: flex;
  align-items: center ;
  justify-content: center;
  width: 100%;
  border-radius: 2em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.07);
}

.ul-class {
  width: 100%;
  list-style-type: none;
  padding: 0;
  font-size: 0.765em;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  position: absolute;
  z-index: 1;
  background: #fff;
  margin-top: 7px;
  border-radius: 0.5em;
  opacity: 0.9;
}

.li-class {
  padding: 0.5em 0 0.5em 2em;
  cursor: pointer;
}

.li-class:hover {
  color: #005d92;
  background: #fdfdfd;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0.5em;
}

.current {
  background: #005d92;
  color: white;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.ul-class .li-class.current:first-child {
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ul-class .li-class.current:last-child{
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

</style>
